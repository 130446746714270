<template>
    <div>
        <ts-page-title
            :title="$t('firstCyclePaid.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('firstCyclePaid.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class="tw-flex tw-ml-6">
                    <div>
                        <label class="col-label form-label tw-text-xs"
                            >Year</label
                        >
                        <DatePicker
                            class="tw-space-x-2 tw-ml-2"
                            type="year"
                            format="yyyy"
                            placeholder="Select Year"
                            :value="year"
                            @on-change="onChangeYear"
                        ></DatePicker>
                        <div
                            class="text-danger"
                            v-if="errors.has('cycle_year')"
                        >
                            {{ errors.first('cycle_year') }}
                        </div>
                    </div>
                    <div class="tw-ml-6">
                        <label class="col-label form-label tw-text-xs"
                            >Month</label
                        >
                        <DatePicker
                            class="tw-space-x-2 tw-ml-2"
                            type="month"
                            format="MM"
                            placeholder="Select Month"
                            :value="month"
                            @on-change="onChangeMonth"
                        ></DatePicker>
                        <div
                            class="text-danger"
                            v-if="errors.has('cycle_month')"
                        >
                            {{ errors.first('cycle_month') }}
                        </div>
                    </div>
                    <div class="tw-ml-2">
                        <Poptip
                            confirm
                            :title="$t('areYouSure')"
                            @on-ok="remove(removing)"
                            :transfer="true"
                            :ok-text="$t('yes')"
                            :cancel-text="$t('no')"
                        >
                            <ts-button
                                color="danger"
                                outline
                                :waiting="removing"
                            >
                                {{ $t('remove') }}
                            </ts-button>
                        </Poptip>
                    </div>
                </div>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        v-model="search"
                        search
                        :placeholder="$t('search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                size="small"
                max-height="700"
                :loading="loading"
                :data="resources"
                stripe
            >
                <template slot-scope="{ row }" slot="cycleYear">
                    {{ row.cycle_year }}
                </template>
                <template slot-scope="{ row }" slot="cycleMonth">
                    {{ toMonthName(row.cycle_month) }}
                </template>
                <template
                    slot-scope="{ row }"
                    slot="basic_salary"
                    class="tw-flex tw-justify-between"
                >
                    {{ formatNumber(row.basic_salary) }}
                </template>
                <template
                    slot-scope="{ row }"
                    slot="basic_earned"
                    class="tw-flex tw-justify-between"
                >
                    {{ formatNumber(row.basic_earned) }}
                </template>
                <template
                    slot-scope="{ row }"
                    slot="total_allowance_before_tax"
                    class="tw-flex tw-justify-between"
                >
                    {{ formatNumber(row.total_allowance_before_tax) }}
                </template>
                <template
                    slot-scope="{ row }"
                    slot="salary"
                    class="tw-flex tw-justify-between"
                >
                    {{ formatNumber(row.net_salary) }}
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t('branchName')
                }}</label>
                <ts-branch-filter @filter="value => (branch_id = value)" />
            </div>
        </Drawer>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'
export default {
    name: 'first-cycle-paid',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            removing: false,
            showForm: false,
            width: 'width: 300px',
            year: moment().format('YYYY'),
            month: moment().format('MM'),
            visible: false,
            branch_id: []
        }
    },
    computed: {
        ...mapState('payroll/firstCyclePaid', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.payroll.firstCyclePaid.search
            },
            set (newValue) {
                this.$store.commit(
                    'payroll/firstCyclePaid/SET_SEARCH',
                    newValue
                )
                this.$store.commit('payroll/firstCyclePaid/RESET_CURRENT_PAGE')
            }
        },
        ...mapGetters(['formatNumber']),
        columns () {
            return [
                {
                    title: this.$t('firstCyclePaid.branchNameEN'),
                    key: 'branch_name_en',
                    sortable: true,
                    width: '180'
                },
                {
                    title: this.$t('firstCyclePaid.cadId'),
                    key: 'card_id',
                    sortable: true,
                    width: '100',
                    align: 'center'
                },
                {
                    title: this.$t('firstCyclePaid.employeeNameKh'),
                    key: 'employee_name_kh',
                    sortable: true,
                    width: '180'
                },
                {
                    title: this.$t('firstCyclePaid.employeeNameEn'),
                    key: 'employee_name_en',
                    sortable: true,
                    width: '180'
                },
                {
                    title: this.$t('firstCyclePaid.sex'),
                    key: 'sex',
                    sortable: true,
                    width: '100',
                    align: 'center'
                },
                {
                    title: this.$t('firstCyclePaid.jobTitleKh'),
                    key: 'job_title_kh',
                    sortable: true,
                    width: '150'
                },
                {
                    title: this.$t('firstCyclePaid.cycleStateDate'),
                    key: 'cycle_start_date',
                    sortable: true,
                    width: '180',
                    align: 'center'
                },
                {
                    title: this.$t('firstCyclePaid.cycleEndDate'),
                    key: 'cycle_end_date',
                    sortable: true,
                    width: '180',
                    align: 'center'
                },
                {
                    title: this.$t('firstCyclePaid.positionId'),
                    key: 'position_name_en',
                    width: '130px',
                    sortable: true
                },
                {
                    title: this.$t('firstCyclePaid.cycleYear'),
                    slot: 'cycleYear',
                    sortable: true,
                    width: '120px',
                    align: 'center'
                },
                {
                    title: this.$t('firstCyclePaid.cycleMonth'),
                    slot: 'cycleMonth',
                    sortable: true,
                    width: '150px',
                    align: 'center'
                },
                {
                    title: this.$t('firstCyclePaid.basicSalary'),
                    slot: 'basic_salary',
                    sortable: true,
                    width: '150px',
                    align: 'right'
                },
                {
                    title: this.$t('firstCyclePaid.basicEarned'),
                    slot: 'basic_earned',
                    sortable: true,
                    width: '150px',
                    align: 'right'
                },
                {
                    title: this.$t('firstCyclePaid.totalAllowanceBfore'),
                    slot: 'total_allowance_before_tax',
                    sortable: true,
                    width: '220px',
                    align: 'right'
                },
                {
                    title: this.$t('firstCyclePaid.salary'),
                    slot: 'salary',
                    sortable: true,
                    align: 'right',
                    width: '150px'
                }
            ]
        }
    },
    methods: {
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/firstCyclePaid/fetch', attributes)
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },

        onChangeYear (year) {
            this.year = year
            this.fetchData({
                filter: {
                    cycle_year: year,
                    cycle_month: this.month
                }
            })
        },
        toMonthName (monthNumber) {
            const date = new Date()
            date.setMonth(monthNumber - 1)

            return date.toLocaleString('en-US', {
                month: 'long'
            })
        },
        onChangeMonth (month) {
            this.month = month
            this.fetchData({
                filter: {
                    cycle_month: month,
                    cycle_year: this.year
                }
            })
        },
        remove () {
            this.removing = true
            this.$store
                .dispatch('payroll/firstCyclePaid/remove', {
                    cycle_month: this.month,
                    cycle_year: this.year
                })
                .then(response => {
                    this.fetchData()
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.removing = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'MONTHLY INCENTIVE BONUS',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({
                filter: {
                    search: search,
                    cycle_year: this.year,
                    cycle_month: this.month
                }
            })
        }, 500),
        branch_id: debounce(function (value) {
            this.fetchData({
                branch_id: value,
                filter: {
                    cycle_year: this.year,
                    cycle_month: this.month
                }
            })
        }, 500)
    }
}
</script>

<style lang="scss" scoped></style>
